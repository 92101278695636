<template>
  <div class="practice-new">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>课程管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/manage/study/course' }">课程列表</el-breadcrumb-item>
        <el-breadcrumb-item><span class="itClass" @click="tolog(courseId)">课程详情</span></el-breadcrumb-item>
        <el-breadcrumb-item>发布练习</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="content">
      <div class="title">
        <div class="question-opt">
          <Tooltip placement="top" content="返回">
            <Icon type="ios-arrow-back" @click="tolog(courseId)" size="24" />
          </Tooltip>
        </div>
        <!--        <span>发布练习</span>-->
        <Button
          type="primary"
          :loading="releaseLoading"
          @click="handleReleasePractice"
          :disabled="!practice.question.length"
          style="margin-left: 20px"
          >发布练习
        </Button>
      </div>

      <CommonPractice ref="practiceForm" :course-id="courseId" v-model="practice" />
    </div>
  </div>
</template>

<script>
import courseApi from '@api/course'
import examApi from '@api/exam'
import CommonPractice from '../../course/practice/CommonPractice'
import PracticeView from '@components/manage/course/practice/NewPractice'
import { PRACTICE_STATUS } from '@/util/practiceStatus'
export default {
  components: {
    CommonPractice
  },
  data: function() {
    return {
      saveLoading: false,
      IsEditor: false,
      releaseLoading: false,
      itChapter: {},
      questionList: null,
      itSectionId: 0,
      practice: {
        id: 0,
        practiceId: 0,
        name: '',
        sectionId: [],
        question: []
      }
    }
  },
  mounted() {
    if (this.$route.query['isEditor']) {
      this.practice.name = this.$route.query['priTitle']
      this.practice.id = this.$route.query['id']
      examApi.getpracticeById(this.practice.id).then(res => {
        this.questionList = res.res
        this.practice.question = res.res.question
        this.practice.practiceId = res.res.practiceId
        this.$refs.practiceForm.setSections()
      })
    }
  },
  methods: {
    tolog() {
      this.itChapter = this.$route.query.chapters
      this.itSectionId = this.$route.query.chapter.secChaper
      this.$router.push({
        name: 'courseDetail',
        params: { courseId: this.courseId },
        query: {
          itChapter: this.itChapter,
          itSectionId: this.itSectionId
        }
      })
    },
    handleSavePractice() {
      this.$refs.practiceForm.validate(valid => {
        if (!valid) {
          return
        }

        this.saveLoading = true
        this.addPractice(PRACTICE_STATUS.EDIT.value)
          .then(() => {
            this.$message.success('保存成功')
            this.$router.push({
              name: 'courseDetail',
              params: { courseId: this.courseId }
            })
          })
          .finally(() => {
            this.saveLoading = false
          })
      })
    },
    message(msg, type) {
      this.$message({ message: msg, type: type })
    },
    handleReleasePractice() {
      // this.$refs.practiceForm.validate(valid => {
      //   if (!valid) {
      //     return
      //   }
      this.releaseLoading = true
      if (this.$route.query['isEditor']) {
        let form = {
          name: this.practice.name,
          practiceId: this.$route.query['id'],
          questionIds: [],
          status: 2
        }
        this.practice.question.forEach(item => {
          form.questionIds.push(item.id)
        })
        examApi
          .updatePracticeByForm(form)
          .then(res => {
            if (res.code === 0) this.message('更新成功', 'success')
            this.itChapter = this.$route.query.chapters
            this.$router.push({
              name: 'courseDetail',
              params: { courseId: this.courseId },
              query: { itChapter: this.itChapter }
            })
          })
          .finally(() => {
            this.releaseLoading = false
          })
      } else {
        this.addPractice(PRACTICE_STATUS.PUBLISH.value)
          .then(() => {
            this.$message.success('发布成功')
            this.itChapter = this.$route.query.chapters
            this.$router.push({
              name: 'courseDetail',
              params: { courseId: this.courseId },
              query: { itChapter: this.itChapter }
            })
          })
          .finally(() => {
            this.releaseLoading = false
          })
      }
      // })
    },
    addPractice(status) {
      if (this.$route.query.isChapter === '1') {
        return courseApi.addChapterPractice({
          courseId: this.courseId,
          endTime: this.practice.endTime ? this.practice.endTime.valueOf() : '',
          name: this.practice.name,
          status: status,
          questionList: this.practice.question.map(question => question.questionId),
          chapterId: this.$route.query.chapters
        })
      } else {
        return courseApi.addPractice({
          courseId: this.courseId,
          endTime: this.practice.endTime ? this.practice.endTime.valueOf() : '',
          name: this.practice.name,
          status: status,
          questionList: this.practice.question.map(question => question.questionId),
          // sectionId: this.practice.sectionId[1] //小节 id 在数组第二位
          sectionId: this.$route.query.chapter.secChaper
        })
      }
    }
  },
  computed: {
    courseId() {
      return this.$route.query.courseId
    }
  }
}
</script>

<style lang="less">
@import '../../../../theme/variables.less';

.practice-new {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  background-color: @layout-body-background;
  overflow: auto;
  text-align: left;

  .content {
    background-color: white;
    box-shadow: 0px 0px 3px 0px rgba(7, 27, 41, 0.1);
    margin-top: 10px;

    .title {
      display: flex;
      align-items: center;
      /*justify-content: space-between;*/
      padding: 10px 20px;
      background-color: #e4e9ef;
      font-size: 16px;
      color: @font-color-content;
    }
    .submit-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      background-color: #e4e9ef;

      .ivu-btn {
        font-size: 14px;
        padding: 2px 12px;
      }
    }
  }
}
</style>
